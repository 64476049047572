import { observable, action, autorun } from 'mobx';
import axios from 'axios';
import { LinkManagerTarget } from './../utils/linkmanager';
import RootStore from './RootStore';

export default class LinkManagerStore {
  constructor(private rootStore: RootStore) {
    autorun(() => {
      this._loadAnchor();
    });
  }

  @observable
  open = false;

  @observable
  code = "";

  @observable
  targetList = new Array<LinkManagerTarget>();

  @action
  setOpen(value: boolean) {
    this.open = value;
  }

  @action
  setCode(value: string) {
    this.code = value;
    this.open = true;
  }

  private _loadAnchor = async () => {
    if (this.code) {
      const response = await axios.get<any>(`https://linkmanager.bris.nl/api/anchor/get?id=${encodeURIComponent(this.code)}`);

      if (response.status === 200) {
        const tl = new Array<LinkManagerTarget>();        

        if (response.data.TargetList) {
          response.data.TargetList.forEach((targetData: any) => {
            const t = new LinkManagerTarget();
            t.fromServer(targetData);
            tl.push(t);
          });
        }

        this.targetList = tl;
      }      
    }
  }
}